import { isEmpty } from "lodash-es";
import useHttp from "~/composables/useHttp";
import { API_MINOR_VERSION, DEFAULT_ERROR_MESSAGE } from "~/constants";
import type { FeaturedRestaurant, RestaurantTags } from "~/types/Restaurant";
import type { Branch } from "~/types/Branch";
import type { APILink } from "~/types/Common";
import adsMapper from "~/helpers/adsMapper";

type SectionTemplate = "group_card" | "outlet_4" | "outlet_6" | "";

type SectionData = {
  message: string | null;
  title: string;
  data: FeaturedRestaurant[] | RestaurantTags[] | Branch[];
  clevertapEventName: string;
  links: APILink;
  ads?: {
    data: FeaturedRestaurant[] | RestaurantTags[] | Branch[];
  };
  slug: string;
  footer: string;
  description: string;
  tagLine: string;
  punchLine: string;
  sectionTemplate: SectionTemplate;
  sectionImage: {
    url: string;
  };
  success: boolean;
  icon: {
    url: string;
  };
};

export async function getHomeSection({
  pageNumber,
  pageSize,
  order,
  lat,
  lng,
  cityId,
  endPointApi,
}: {
  pageNumber?: number;
  pageSize?: number;
  endPointApi?: string;
  lat?: number;
  lng?: number;
  order?: number;
  cityId?: number | string;
}): Promise<{ isSuccess: boolean; data: null | SectionData; message: string }> {
  if (typeof order !== "number") {
    return {
      isSuccess: false,
      message: "Invalid order or missing order",
      data: null,
    };
  }
  const defaultErrorMessage = `${DEFAULT_ERROR_MESSAGE}, failed get section data`;

  let url = `${endPointApi}`;
  if (!endPointApi) {
    url = `/homes/section_${order}.json`;
  }

  const param: {
    cityId?: number | string;
    apiMinorVersions: string | number;
    page?: {
      size: number;
      number: number;
    };
    location?: {
      lat: number;
      lng: number;
    };
  } = {
    cityId,
    apiMinorVersions: API_MINOR_VERSION,
  };

  if (pageNumber && pageSize) {
    param.page = {
      size: pageSize,
      number: pageNumber,
    };
  }

  if (lat && lng) {
    param.location = {
      lat,
      lng,
    };
  }

  const { data, error } = await useHttp({
    url,
    method: "GET",
    params: param,
  });

  const responseData = data as SectionData;
  if (isEmpty(responseData)) {
    return {
      isSuccess: false,
      message: error.message || defaultErrorMessage,
      data: null,
    };
  }

  if (!responseData.success) {
    return {
      isSuccess: false,
      message: responseData.message || defaultErrorMessage,
      data: null,
    };
  }

  if (responseData.ads && Array.isArray(responseData.ads.data)) {
    responseData.ads.data.forEach((currentAds) => {
      currentAds.attributes.isAds = true;
      adsMapper(
        currentAds,
        responseData.data,
        (pageSize = 20),
        (pageNumber = 1)
      );
    });
  }
  return {
    isSuccess: true,
    message: "",
    data: responseData,
  };
}
